import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    errorMessage!: string | null;
    loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
    });

    constructor(private fb: FormBuilder,
                private authService: AuthService,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.errorMessage = null;
        if (this.loginForm.invalid) {
            this.loginForm.markAllAsTouched();
            return;
        }
        this.authService.login(this.loginForm.value).subscribe((res) => {
            if(res.admin.role ==='landlord'){
                this.router.navigate(['/landloard-dashboard']);
            }
            else{
                this.router.navigate(['/events']);
            }
            
        }, (error: HttpErrorResponse) => {
            if (error.status === 401) {
                this.errorMessage = error.error.message;
            }
        });
    }

}
